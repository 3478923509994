@import '../../../style/variables.scss';

.mapboxgl-map .mapboxgl-popup-content {
  max-width: 330px;
  min-width: 250px;
  padding: 10px 20px;

  .popup-bridge {
    .bridge-request,
    .bridge-request-preview {
      font-size: $font-size-m;
      padding: 5px 10px;
      .button-wrapper > * {
        button {
          margin: 0px;
        }
        max-width: 100%;
      }
    }
  }

  .ship-popup {
    padding: 10px;
  }
  label {
    margin-right: 10px;
    color: $font-light;
    min-width: 150px;
    display: inline-block;
  }
  button {
    background-color: $color-primary;
    color: $color-white;
    margin-top: 10px;
    border: inherit;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    &:hover {
      opacity: 0.8;
    }
  }
  .close {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    font-size: 15px;
    cursor: pointer;
  }

  .popup-bridge {
    .close {
      font-size: 20px;
      z-index: 2;
    }
    .bridge-title {
      position: relative;
      padding: 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgb(218, 218, 218);

      h2,
      h3 {
        margin: 0;
        color: $color-primary;
        font-weight: $font-weight-bold;
      }

      h2 {
        font-size: $font-size-m;
      }

      .icon-wrapper {
        margin-right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        border-radius: 50%;
        padding: 3px 6px;
        box-sizing: border-box;
        background-color: $color-highlight;
        border: 3px solid #79baf3;

        span {
          color: $color-white;
          font-size: $font-size-xl;
        }
      }
    }

    .item-info-details {
      max-height: 220px;
      overflow: auto;
      padding: 16px;
      padding-top: 13px;
    }
  }
}

.route-network-legend-wrapper {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 11;
  box-shadow: $map-element-shadow;

  @media screen and (max-width: 575px) {
    top: 85px;
  }
}
