@import '../../../style/variables.scss';

.key-value {
  font-size: $font-size-m;
  display: flex;
  margin-bottom: 2px;
  
  label {
    white-space: nowrap;
    min-width: 140px;
    padding-right: 5px;
    padding-top: 2px;
    font-weight: bold;
  }

  span {
    color: $color-primary;
  }
}