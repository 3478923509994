@import '../../style/variables.scss';

.notification-list {
  .list-header {
    position: relative;
    h4 {
      background-color: $color-background-light;
      margin: 0;
      padding: 15px 25px;
      border-bottom: 1px solid $color-background-light;
    }
  }

  ul {
    list-style: none;
    padding: 15px 25px;
    margin: 0;

    li {
      padding: 5px 0px;
      font-size: $font-size-m;
      color: $font-light;
    }
  }
}
