@import '../../style/variables.scss';

.locale-wrapper {
  position: relative;

  .locale-item {
    display: flex;
    align-items: center;
    width: 100%;
    transition: all ease-in-out 200ms;
    text-align: center;

    .locale-name, .names .locale-name {
      font-weight: 600;
      font-size: 16px;
      width: 100%;

      &.small {
        font-size: 12px;
        color: $color-dark-gray;
      }
    }

    .locale-flag {
      min-height: 20px;
      min-width: 30px;
      height: 20px;
      width: 30px;
      margin-right: 8px;
      border-radius: 4px;
    }
    
    &.current {
      padding: 12px;
      border: 1px solid #ebebeb;
      border-radius: $border-radius;
      transition: all ease-in-out 200ms;
    }
    
    &:hover, &:focus {
      cursor: pointer;
      background-color: $color-primary;
      color: #fff;
      
      .locale-name.small {
        color: #fff;
      }
    }

    i {
      transition: all ease-in-out 200ms;
      min-width: 30px;
      margin-left: 4px;
      
      &.rotated {
        transform: rotate(-180deg);
      }
    }
  }

  &.selecting {
    .locale-item {
    }
  }
  

  .drop-down-menu {
    position: absolute;
    right: 0px;
    min-width: 100%;
    background-color: #fff;
    border-radius: $border-radius;
    overflow: hidden;
    border: 1px solid #ebebeb;
    margin-top: -1px;
    z-index: 10000;

    &.persisted {
      position: relative;
    }

    .locale-item {
      padding: 8px 12px;

      .locale-name {
        font-size: 14px;
      }

      &:nth-child(even), &:last-child {
        border-top: 1px solid #ebebeb;
      }
    }
  }
}

@-moz-keyframes flip { 100% { -moz-transform: rotate(180deg); } }
@-webkit-keyframes flip { 100% { -webkit-transform: rotate(180deg); } }
@keyframes flip { 100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); } }

.locale-flag {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 40px;

  &.nl_NL {
    background-image: url('../../assets/flags/nl_NL.png');
  }

  &.en_GB {
    background-image: url('../../assets/flags/en_GB.png');
  }

  &.frl_NL {
    background-image: url('../../assets/flags/frl_NL.png');
  }

  &.de_DE {
    background-image: url('../../assets/flags/de_DE.png');
  }
}