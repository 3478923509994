@import '../../../../../style/variables.scss';

.saved-route-wrapper {
  background-color: $color-primary;
  padding: 25px;
  margin-top: $spacing-default;
  color: #fff;
  box-shadow: $default-shadow;
  display: flex;

  .route-title {
    font-weight: 700;
  }

  .route-property {
    .property-title {
      font-size: $font-size-s;
      margin-right: 5px;
    }
    .property-value {
      font-size: $font-size-m;
      font-weight: bold;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .button {
      margin-top: 8px;
      &:first-child {
        margin: 0px;
      }
    }
    // &:first-child {
    //   margin: 0px;
    // }

  }
}
