@import '../../../style/variables.scss';

.selected-route-item-wrapper {
  position: absolute;
  bottom: 100px;
  left: 10px;
  right: 10px;
  max-height: 90%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  z-index: 9999;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  box-shadow: $default-shadow;
  font-family: $font-standard;
  min-height: 100px;
  max-width: 500px;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 800px) {
    left: calc(50% - 250px);
  }

  .route-item-titles {
    position: relative;
    padding: 15px 25px;
    display: flex;
    align-items: center;

    h2,
    h3 {
      margin: 0;
      color: $color-primary;
      font-weight: 700;
    }

    h2 {
      font-size: $font-size-m;
    }

    .icon-wrapper {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      border-radius: 50%;
      padding: 3px 6px;
      box-sizing: border-box;
      background-color: $color-medium-gray;
      border: 3px solid rgb(218, 218, 218);

      span {
        color: #fff;
        font-size: 18px;
      }

      &.bridge {
        background-color: $color-light-blue;
        border: 3px solid $color-background-light;
      }

      &.lock {
        background-color: $color-purple;
        border: 3px solid #986abe;
      }

      &.watermeter {
        background-color: $color-dark-blue;
        border: 3px solid #486785;
      }

      &.notification {
        margin-right: 15px;
        background-color: $color-orange;
        border: 3px solid rgb(248, 214, 152);
      }

      &.vhfsector {
        background-color: $color-dark-blue;
        border: 3px solid #486785;
      }
    }

    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: $font-size-l;
    }
  }

  .basic-info {
    padding: 0px 25px 25px 25px;
    border-top: 2px solid $color-background-light;

    .bridge-warning {
      padding-top: 10px;
      color: $color-light-red;
      font-weight: 700;
    }

    .info-item {
      margin-top: 25px;
      display: inline-block;
      margin-right: 15px;

      .info-tag {
        color: $color-medium-gray;
      }

      .info-span {
        box-sizing: border-box;
        margin-top: 5px;
        display: block;
        padding: 6px 10px;
        background-color: $color-primary;
        color: #fff;
        border-radius: $border-radius;
        border: 2px solid $color-primary;
        font-weight: 700;

        &.vhf {
          border: 2px solid #c11111;
          background-color: #fff;
          color: $color-dark-gray;
        }
      }
    }
  }

  .route-item-detailed {
    background-color: $color-background-light;
    padding: 15px 25px;
    position: relative;

    .item-info-details {
      padding: 15px 0px;
    }

    .details-bar {
      width: 100%;
      text-align: center;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        position: absolute;
        top: 10px;
        right: 15px;
        width: 25px;
        box-sizing: border-box;
      }

      .collapse-icon {
        border-radius: $border-radius;
        padding: 5px;
        font-size: $font-size-l;
      }
    }
  }
}
