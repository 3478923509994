@import '../../../style/variables.scss';

.activate-route-select {
  width: 100%;
  display: flex;
  align-items: center;
  color: $color-light-gray;
  font-weight: 700;
  padding: 10px 10px 10px 40px;
  font-size: $font-size-m;
  cursor: pointer;
}