@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Roboto:400,500,700');
@import './variables.scss';
@import '../assets/fontello/css/animation.css';
@import '../assets/fontello/css/teqplay-icons.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body,
html {
  margin: 0;
  padding: 0;

  color: $font-color;
  font-family: $font-standard;
  background-color: $color-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

body {
  background-image: url('../images/splash_img.png');
  background-size: 100px 143px;
  background-repeat: no-repeat;
  background-position: center;
}

// html {
//   touch-action: manipulation;
// }

#root {
  width: 100%;
  height: 100%;
}

input[type=text], 
input[type=password],
input[type=submit],
input[type=mail],
input[type=number],
input[type=button] {
  box-sizing: border-box;
  font-family: $font-standard;
  font-size: $font-size-m;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mm-popup__btn--button,
.button {
  border: none;
  outline:none;
  border-radius: $border-radius;
  font-family: $font-standard;
  font-size: $font-size-m;
  box-sizing: border-box;
  font-weight: 700;
  -webkit-appearance: none;
  padding: 7px 10px;
  user-select: none;

  &.mm-popup__btn--large,
  &.large {
    padding: 10px 25px;
  }

  &.mm-popup__btn--small,
  &.small {
    padding: 7px 15px;
  }

  &.mm-popup__btn--primary,
  &.primary {
    background-color: $color-primary;
    color: #fff;
    border: 1px solid $color-primary;
  }

  &.disabled-button{
    background-color: $color-background-light;
    color: $font-light;
    border: 1px solid $color-background-light;
  }

  &.mm-popup__btn--danger,
  &.danger {
    border: 1px solid $color-error;
    background-color: #fff;
    color: $color-error;
    font-weight: 700;
  }

  &.mm-popup__btn--empty,
  &.empty {
    background-color: #fff;
    color: $color-light-gray;
  }

  &.secondary {
    background: #fff;
    color: $color-primary;
    font-weight: bold;
  }

  &:hover{
    cursor: pointer;
  }
}

.right {
  float: right;
  
  &.border-gray{
    border: 1px solid $color-background-light;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: $color-lighter-gray;
  margin: 10px 0;
}

* {
  box-sizing: border-box;
}

.page-block {
  background: #fff;
  padding: 20px;
  border-radius: 3px;
  max-width: 820px;
  margin: 0 auto 10px;
}

h3 {
  font-size: $font-size-l;
  margin: 2px 0 5px;
}

.form-row {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.label {
  flex: 1;
  max-width: 200px;
  margin-right: 10px;
  display: inline-block;
  color: $font-light;
}

.input {
  flex: 1;
  display: inline-block;
  padding: 6px 8px;
  border: 1px solid $color-border-light;
  border-radius: 3px;
  max-width: 230px;
  width: 100%;
}

.__react_component_tooltip {
  max-width: 200px;
}

.modal {
  padding: 35px;

  &.full-screen {
    max-height: none;
    width: 90%;
    max-width: 400px;
  }

  .close-modal {
    position: absolute;
    font-size: 12px;
    right: 0;
    top: 5px;
    padding: 10px;
    cursor: pointer;
  }
}

@media (max-width: 420px) {
  .form-row {
    display: block;
  }

  .label {
    display: block;
    margin-bottom: 3px;
  }

  .input {
    display: block;
  }

  .button{
    font-size: $font-size-s;
  }

  .modal {
    padding: 25px;
    .close-modal {
      padding: 5px;
    }
  }
}

.save-inner i {
  margin-right: 8px;
}


// // iphone x

.options-toggle {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.menu .menu-footer {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.navigation-page .navigation-options-wrapper {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

a, button, select, input {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

.bridge-opening-response-wrapper {
  display: flex;
  flex-direction: column;

  height: 100%;

  .line {
    height: unset;
    background-color: unset;
    margin-top: unset;
  }
}

.Toastify__toast-body {
  font-family: $font-standard;
  font-weight: 600;
  font-size: 14px;
}