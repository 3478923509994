@import '../../../../style/variables.scss';

.speed-options {
  .input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    .input {
      border-radius: $border-radius;
      font-size: 14px;
      color: $color-primary;
      font-weight: 700;
      padding: 15px;
      text-align: right;
    }
  }

  .subtitle {
    font-size: 13px;
    margin-top: 6px;
    color: $color-light-gray;
  }
}