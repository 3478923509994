@import '../../style/variables.scss';

.modal.disclaimer {
  width: 90%;
  max-width: 460px;
  padding: 30px 40px 40px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 21px -3px #2c2c2c;
  text-align: center;
  margin: 0 auto;
  border-bottom: 10px solid #c73326;
}

.disclaimer-wrapper {
  text-align: left;

  h2 {
    font-size: $font-size-xxl;
    margin: 0;
  }

  p {
    line-height: 1.8;
    font-size: $font-size-l;
  }
}

.route-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;

  &.active-route-list {
    height: 100%;
  }

  .route-options-list {
    .slick-slide {
      width: 300px;

      .route-option {
        background-color: #034075;
      }
    }

    .slick-center {
      width: 600px;

      .route-option {
        background-color: $color-primary;
      }
    }
  }

  .selected-route-overview {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    .options-select-route-location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-light-gray;
      font-weight: 700;
      display: inline-block;
      max-width: 125px;
    }

    i {
      color: $color-primary;
      margin-left: 10px;
      margin-right: 13px;
    }
  }

  .select-inline-inner-wrapper {
    display: flex;
    align-items: center;
    font-size: $font-size-m;
    background-color: #fff;
    padding: 5px;
    box-shadow: $map-element-shadow;
    margin: 0;
  }

  .select-multiline-inner-wrapper {
    display: block;
    font-size: $font-size-m;
    background-color: #fff;
    padding: 20px;
    box-shadow: $map-element-shadow;
    margin: 0;
    position: relative;
  }

  .route-select-menu-buttons {
    margin-right: 10px;
    color: $color-primary;
    display: block;

    .close-button {
      position: absolute;
      top: 20px;
      left: 15px;
      z-index: 5;
      font-size: 17px;
      cursor: pointer;
    }
  }

  .textfield {
    width: 100%;
    flex: 1 1;
    border: none;
    font-weight: 700;
    padding: 15px;
    font-size: $font-size-m;
    color: $color-primary;
    margin-top: 10px;

    &.first {
      margin-bottom: 0px;
    }

    &.textfield-location {
      background-color: $color-background-light;
      border-radius: $border-radius;
      cursor: pointer;
    }
  }
}

.not-allowed {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  padding: 10px;
  color: $color-light-red;

  .slick-center & {
    opacity: 1;
    pointer-events: auto;
  }
}

.warning-tooltip {
  background-color: $color-light-red !important;
}

#shipNotAllowed {
  &:after {
    display: none;
  }
}

.route-option-modal-wrapper {
  padding: 0;
}

.route-option-modal-wrapper,
.route-option-wrapper {
  padding-right: 10px;
  box-sizing: border-box;
  outline: none;

  &.option-not-accessible {
    .route-option {
      border-top: 5px solid $color-light-red;
    }
  }

  .route-option {
    position: relative;
    color: #fff;
    padding: 15px;

    .option-title {
      margin: 0;
      font-size: $font-size-s;
    }

    .option-details {
      h3 {
        font-size: $font-size-xxl;
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: 0px;

        @media screen and (max-width: 600px) {
          font-size: $font-size-l;
        }
      }

      p {
        margin: 0;
        font-size: $font-size-m;

        @media screen and (max-width: 600px) {
          font-size: $font-size-s;
        }
      }
    }
  }

  .list-buttons {
    background-color: #1d60aa;
    color: #fff;
    font-weight: 700;
    outline: 0;
    font-size: $font-size-m;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;
    box-shadow: $default-shadow;
    margin-bottom: 3px;
    display: flex;

    .single-button {
      padding: 10px 5px;
      outline: 0;
      flex: 1;
      text-align: center;
      cursor: pointer;

      &:first-child {
        flex: 0.5;
        border-right: 1px solid #0155a5;
      }
    }

    .slick-center & {
      opacity: 1;
      pointer-events: auto;
    }

    i {
      margin-right: 2px;
    }
  }
}

.route-warning-not-sailing {
  background: $color-error;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.labels-for-details {
  margin: 15px 0;
}

.option-dimensions {
  margin-top: 25px;

  .dimension {
    margin-right: 20px;
    margin-bottom: 10px;

    .dimension-label {
      font-size: $font-size-m;
      font-weight: 700;
    }

    .dimension-value {
      margin-top: 5px;
      border-radius: $border-radius;
      font-weight: 700;
      font-size: $font-size-xl;
      color: $color-primary;

      @media screen and (max-width: 600px) {
        font-size: $font-size-l;
      }

      [class^='icon-']:before {
        font-size: $font-size-xl;
      }
    }
  }
}

.route-list-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 25px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  position: relative;
  box-shadow: $default-shadow;
  border-top: 3px solid $color-light-red;
  max-height: calc(100% - 200px);
  min-height: calc(100% - 200px); // - the offset of the top route selection carousel height (137px), the bottom button bar height(63px) and some padding

  .route-info-inner {
    padding: 20px;
  }

  @media screen and (max-width: 400px) {
    max-height: calc(100% - 225px);
    min-height: calc(100% - 200px);
  }
}

.route-options-button-wrapper {
  display: flex;
  justify-content: center;
  max-width: 450px;

  button {
    margin: 5px;
    font-weight: 700;
    box-shadow: $map-element-shadow;

    &.list-button {
      background-color: #fff;
      color: $color-primary;
    }
  }
}

.save-route-modal {
  overflow: auto !important;

  .input-wrapper {
    margin-bottom: 10px;

    label {
      display: block;
      font-size: $font-size-m;
    }

    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .route-options-button-wrapper {
    max-width: none;
  }
}

@media screen and (max-width: 340px) {

  .route-option-modal-wrapper,
  .route-option-wrapper {
    .list-buttons {
      flex-direction: column;

      .single-button {
        &:first-child {
          border-right: none;
          border-bottom: 1px solid #0155a5;
        }
      }
    }
  }
}