@import '../../style/variables.scss';


.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $color-primary;
  position: relative;

  .locale-wrapper {
    margin-bottom: $spacing-default;
  }

  .disclaimer-wrapper {
    text-align: left;

    h2 {
      font-size: $font-size-xxl;
      margin: 0;
    }

    p {
      line-height: 1.8;
      font-size: $font-size-l;
    }
  }

  .login-form-wrapper {
    width: 90%;
    max-width: 460px;
    padding: 30px 40px 40px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 21px -3px $color-dark-blue;
    text-align: center;
    margin: 0 auto;
    border-bottom: 10px solid $color-light-red;
  }

  .input-wrapper {
    position: relative;

    i {
      position: absolute;
      left: 0;
      top: 14px;
      color: $color-border-light;
      font-size: 18px;
      width: 20px;
      text-align: center;

      &.icon-mail-alt {
        font-size: 16px;
      }
    }
  }

  .login-header {
    margin-bottom: 20px;

    h2 {
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 5px;
    }
  }

  .react-select__control,
  .textfield {
    width: 100%;
    box-sizing: border-box;
    border: none;
    margin-bottom: 30px;
    background-color: none;
    outline: none;
    box-shadow: none;
    min-width: 250px;
    border-radius: 0;
    border-bottom: 2px solid $color-border-light;
    outline: none;
    @include placeholder-color($color-light-gray);

    // &:focus,
    // &:hover,
    // &:active {
    //   border-top: none;
    //   border-bottom-color: $color-highlight;
    // }

    &:focus {
      border-color: $color-light-red;
    }
  }

  .react-select__single-value,
  .textfield {
    padding: 15px 15px 15px 35px;
    color: $color-dark-gray;
    font-size: $font-size-m;
    font-weight: 700;
  }

  .welcome-message {
    font-size: $font-size-l;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .error-message {
    width: 100%;
    font-size: $font-size-l;
    font-weight: 700;
    color: #fff;
    margin-bottom: 5px;
    display: block;
    background: $color-error;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 20px;
  }

  .button {
    font-weight: 700;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    margin-top: 10px;
  }

  .login-button {
    background-color: $color-primary;
    color: #fff;
    box-shadow: 0 0 5px -1px $color-primary;
  }
}

.create-account-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-s;
  margin-top: 40px;
  font-weight: bold;

  span {
    color: $color-light-gray;
  }

  a,
  .forgot-pass,
  .policy {
    color: $color-primary;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-error;
  color: #fff;
  padding: 12px;
  border-radius: 3px;
  font-family: $font-standard;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-green;
  color: #fff;
  padding: 12px;
  border-radius: 3px;
  font-family: $font-standard;
}

.new-app-message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green;
  color: #fff;
  width: 100%;
  font-size: $font-size-s;
  font-weight: 700;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 20px;

  i {
    margin-right: 8px;
  }
}


@media screen and (max-width: 600px) {
  .login-container {
    background-position-x: calc(50% + 100px);

    .login-form-wrapper {
      padding: 40px 20px;
    }
  }
}

@media screen and (max-width: 360px) {
  .register-form.login-container {
    .login-form-inner {
      .login-form-wrapper {
        padding: 10px;
      }
    }
  }
}

@media screen and (max-height: 670px) {
  .login-container {
    padding: 0px;
    margin: 0px;

    .login-form-wrapper {
      margin: 0px;
      border-radius: 0px;
      width: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
}