@import '../../style/variables.scss';

.navigation-header {
  height: $navigation-height;
  box-shadow: $map-element-shadow;
  box-shadow: 0 0 5px -2px $color-light-gray;
  z-index: 405;
  position: relative;
  
  .menu-select {
    color: #fff;
  }
}

.top-navigation-bar {
  height: 50px;
  height: calc(50px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  background: $color-primary;
  color: #fff;
  display: flex;
  align-items: center;
  
  .sidebar-content {
    top: env(safe-area-inset-top) !important;
  }
  
  &.paused{
    background: $color-orange;
    border-bottom: 8px solid $color-orange-dark;
  }
}

.bottom-navigation-bar {
  height: 40px;
  background: $color-background-light;
  display: flex;

  .nav-box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-s;

    .header-icon{
      font-size: 15px;
    }

    &.speed-box, &.vhf-box, &.time-box, &.heading-box{
      font-weight: 700;
    }

    &.vhf-box{

      label{
        text-align: center;
        display: block;
        text-transform: uppercase;
        font-size: $font-size-s;
      }

      .vhf-wrapper{
        display: block;
        .current-vhf{
          font-size: $font-size-m;
          color: $color-primary;
        }
        .vhf-next-icon{
          font-size: $font-size-s;
        }
      }
    }

  }
}

.next-item {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;

  label,
  span {
    display: block;
  }

  label {
    color: #fff;
    font-size: $font-size-s;
    font-weight: 700;
  }

  span {
    font-weight: 700;
  }

  .next-item-icon {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 24px;
  }
}
