@import '../../../style/variables.scss';

.set-speed-wrapper {
  padding: 25px;
  max-width: 500px;

  .form-row {
    margin-top: 20px;
    display: block;

    input{
      padding: 10px;
      width: 200px;
      font-size: $font-size-l;
      font-weight: 700;
    }
  }

  .button-container{
    display: block;
    margin-top: 20px;

    button{
      font-weight: 700;
    }
  }

  h2{
    margin:0;
    font-size: $font-size-xl;
  }

  .switch-button{
    width: 100%;
    margin-top: 25px;
    display: flex;

    button{
      flex: 1;
      font-weight: 700;

      &.use-ship-speed{
        flex: 2;
      }
    }
  }
  
  .speed-input {
    width: 80px;
    flex: none;
    margin-right: 10px;
    text-align: center;
    display: inline-block;
  }
}

@media (max-width: 380px) {
  .switch-button {
    .button.large {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}