@import '../../style/variables.scss';

.custom-page-wrapper {
  width: 100%;
  min-height: 100%;
  background: $color-background-light;

  .custom-page {
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
  }
  
  .content {
    margin: 0 auto;
    padding: 10px;
    max-width: 820px;
  }

  &.disableStyling {
    .custom-page {
      max-width: unset;
    }
    .content {
      padding: 0
    }
  }
}

.custom-page-header {
  height: 50px;
  height: calc(50px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  width: 100%;
  background: $color-primary;
  color: #fff;
  border-bottom: 8px solid $color-light-red;

  .sidebar-content {
    top: env(safe-area-inset-top)!important;
  }

  .menu-select {
    padding: 0;
    text-align: left;
  }
  
  .icon-menu {
    color: #fff;
    display: block;
    font-size: 20px;
    padding: 10px;
  }
}