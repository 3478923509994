@import '../../style/variables.scss';

.userzoom-wrapper {
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 20px;
  z-index: 10; // place just above the Mapbox

  button {
    display: block;
    border: inherit;
    padding: 10px;
    background-color: $color-white;
    color: $color-primary;
    width: 50px;
    font-size: 20px;
    margin-bottom: 15px;
    box-shadow: 0 1px 6px -2px $color-dark-gray;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      background-color: $color-primary;
      color: $color-white;
    }
  }
  .userzoom {
    border-radius: 0px;
    box-shadow: 0 1px 6px -2px $color-dark-gray;
    button {
      margin-bottom: 0px;
      border-radius: inherit;
      font-size: 30px;
      box-shadow: inherit;
    }
  }
}

/* For an specific Rotation Component in Mapbox - 
Can't override this one expect inline or important 
*/
.custom-rotation-controll {
  box-shadow: 0 1px 6px -2px $color-dark-gray !important;
  z-index: 10 !important;
  margin-top: 35px !important;
  background-color: $color-white !important;
  border-radius: 3px !important;
  box-shadow: inherit !important;
  button {
    width: 50px !important;
    height: 42px !important;
    padding-top: 8px !important;
    background-color: $color-white !important;
    border-radius: 3px !important;
    box-shadow: 0 1px 6px -2px $color-dark-gray !important;
    &:hover {
      background-color: #f5f5f5 !important;
    }
    svg {
      transform: scale(1.4);
    }
  }
  &.route-navigation {
    margin-top: 100px !important;
  }
}

.custom-layer-control {
  position: fixed;
  z-index: 2;
  right: 80px;
  bottom: 10px;
  max-width: 500px;
  background: $color-white;
  padding: 25px;
  box-shadow: $map-element-shadow;
  border-radius: $border-radius;
  max-height: 70vh;
  overflow: auto;
  pointer-events: all;
  touch-action: pan-x pan-y;
  z-index: 999;
  animation: fadein 0.5s;
  * {
    font-family: $font-standard;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-light-gray;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-dark-blue;
  }

  .map-types {
    .map-type {
      display: inline-block;
      margin-right: 5px;
      border: 1px solid transparent;
      width: 50px;
      height: 50px;
      background-size: cover;

      &.active {
        border-color: $color-primary;
        box-shadow: 0 0 5px 0 $color-primary;
      }

      &.DEFAULT {
        background-image: url('../../images/mapTypes/DEFAULT.png');
      }
      &.SATELLITE {
        background-image: url('../../images/mapTypes/SATELLITE.png');
      }
      &.ECDIS {
        background-image: url('../../images/mapTypes/ECDIS.png');
      }
      &.OPENSTREETMAP {
        background-image: url('../../images/mapTypes/OPENSTREETMAP.png');
      }
    }
  }

  .category {
    margin-bottom: 5px;
  }

  .category-name {
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
    font-size: 13px;
    color: $color-primary;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .category-buttons {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
  }

  .layer-button {
    min-height: 75px;
    flex: 0 0 31.333333%;
    margin: 1%;
    display: inline-block;
    padding: 5px 10px;
    border-radius: $border-radius;
    border: 1px solid $color-background-light;
    background: none;
    margin-bottom: 10px;
    vertical-align: top;
    font-weight: $font-weight-bold;
    color: $font-light;
    outline: none;
    cursor: pointer;
    &:hover {
      border: 1px solid $color-dark-gray;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    &.active {
      color: $color-primary;
      border-color: $color-primary;
    }

    .layer-icon {
      display: block;
      margin-bottom: 5px;
      font-size: $font-size-l;
      cursor: pointer;
    }
  }
}

.zoom-in-message {
  position: fixed;
  bottom: 10px;
  left: calc(50% - 100px);
  z-index: 10;
  background-color: $color-primary;
  padding: 10px 25px;
  font-weight: $font-weight-bold;
  font-family: $font-standard;
  width: 200px;
  color: $color-white;
  border-radius: $border-radius;
  box-sizing: border-box;
  cursor: pointer;

  &.minimized {
    height: 50px;
    color: $color-primary;
    bottom: 0px;
  }

  .inner-wrapper {
    position: relative;

    .open-control {
      position: absolute;
      width: 100%;
      top: -12px;
      text-align: center;
      color: $color-white;
    }

    .minify-control {
      margin-top: 5px;
      width: 100%;
      text-align: center;
      display: block;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.layer-control-wrapper {
  position: absolute;
  right: 57px;
  bottom: 10px;
  left: 10px;
  z-index: 500;

  .close-layers-popup {
    position: absolute;
    padding: 10px;
    right: 0;
    top: 0;
  }
}

.bridges-legend-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bridge-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}

.bridges-legend-button {
  position: fixed;
  bottom: 85px;
  left: 0px;
  font-family: $font-standard;
  background-color: $color-primary;
  color: $color-white;
  padding: 10px 10px 10px 7px;
  z-index: 4;
  box-shadow: $map-element-shadow;
  font-size: 12px;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  cursor: pointer;
}

.route-network-legend-wrapper {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 2;
  box-shadow: $map-element-shadow;

  @media screen and (max-width: 575px) {
    top: 85px;
  }
}

.bridges-legend-wrapper {
  position: fixed;
  bottom: 60px;
  left: 0px;
  z-index: 2;
  box-shadow: $map-element-shadow;
}

.legend-close-button {
  padding: 10px;
  text-align: center;
  background-color: $color-primary;
  color: $color-white;
  z-index: 2;
  box-shadow: $map-element-shadow;
  font-size: 12px;
  border-top-right-radius: $border-radius;
}

.bridges-legend-content,
.route-network-legend {
  font-family: $font-standard;
  background-color: $color-white;
  padding: 15px;
  border-bottom-left-radius: $border-radius;

  .route-legend-item {
    align-items: center;
    display: flex;

    .color-indicator {
      width: 12px;
      height: 12px;
      display: block;
    }

    .legend-tag {
      display: block;
      margin-left: 10px;
      font-weight: $font-weight-bold;
      font-size: 12px;
    }
  }
}

.route-network-legend-button {
  position: absolute;
  font-family: $font-standard;
  top: 15px;
  right: -5px;
  background-color: $color-primary;
  color: $color-white;
  padding: 10px 10px 10px 7px;
  z-index: 10;
  box-shadow: $map-element-shadow;
  font-size: 12px;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  @media screen and (max-width: 575px) {
    top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .custom-rotation-controll {
    margin-top: 70px !important;
  }
  .mapboxgl-compact {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .custom-layer-control .layer-button {
    flex: 0 0 48%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
