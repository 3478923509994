@import '../../style/variables.scss';

.mapboxgl-map {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  user-select: none;

  .mapboxgl-marker {
    //Padding
    top: 10px;
    pointer-events: none;
  }

  .mapbox-improve-map {
    display: none;
  }

  canvas {
    outline: none;
  }

  .mapboxgl-popup {
    z-index: 99 !important; // Override Mapbox inline-style
  }
}

.ship-user-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  box-sizing: border-box;
  color: $color-primary;
  // add white stroke to the marker
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.non-click {
  pointer-events: none;
}

.small-dot-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid #fff;
  box-shadow: $default-shadow;

  &.red {
    background-color: $color-light-red;
  }
}

.default-ship-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-sizing: border-box;
  color: $color-highlight;
  // add white stroke to the marker
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;

  &.orange {
    color: $color-orange;
  }

  &.large {
    width: 35px;
    height: 35px;
    font-size: 35px;
  }
}

.circle-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid #fff;
  box-shadow: $default-shadow;

  &.small {
    font-size: 18px;
  }

  span {
    color: #fff;
    box-sizing: border-box;
  }

  &.blue {
    background-color: $color-highlight;
  }

  &.orange {
    background-color: $color-orange;
  }

  &.dark-orange {
    background-color: $color-orange-dark;
  }

  &.purple {
    background-color: $color-purple;
  }

  &.gray {
    background-color: $color-light-gray;
  }

  &.dark-blue {
    background-color: $color-dark-blue;
  }

  &.red {
    background-color: $color-light-red;
  }

  &.green {
    background-color: $color-green;
  }

  &.openable {
    background-color: $color-highlight;
    background-size: 200% 100%;
    border-color: $color-orange;
  }

  &.clickable {
    cursor: pointer;
  }
}
