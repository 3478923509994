@import '../../../style/variables.scss';

.menu-select {
  &.close-button {
    position: absolute;
    left: 2px;
    z-index: 5;
  }
}

.location-select-wrapper{
  max-width: 450px;
}

.location-select-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-default;

  .button {
    flex: 1;
    padding: 10px 10px;
    font-weight: 700;
    font-size: 13px;

    &.inactive{
      background-color: #fff;
      color: $color-primary;
    }

    &.active{
      background-color: $color-primary;
      color: #fff;
    }

    &.disabled{
      background-color: $color-medium-gray;
      color: #fff;
    }

    i {
      margin-right: 5px;
    }
  }

  .map-location-button {
    margin-left: 15px;
  }
}
