@import '../../style/variables.scss';

.policy-page {
  margin: 0 auto;
  background-color: $color-white;
  width: 100%;
  padding: 10px;

  .policy-container {
    max-width: 1100px;
    margin: 0 auto;

    h4 {
      font-size: 17px;
      margin: 10px 0px;
    }

    .back {
      display: flex;
      margin-top: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}