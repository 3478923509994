@import '../../style/variables.scss';

.bridge-request-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background-color: $color-primary;
  color: #fff;
  font-size: $font-size-l;
  font-weight: 700;
  border-bottom: 4px solid $color-orange;

  &.unhealthy {
    background-color: $color-light-red;
  }
  
  &.in-popup {
    padding: 12px 18px;
    font-size: 13px;
  }
  
  &.in-routeItem {
    padding: 12px 18px;
    font-size: 13px;
  }
  
  &.unapproved {
    display: flex;
    padding: 12px 18px;
    font-size: 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bridge-request {
  padding: 25px;
  background-color: $color-primary;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 4px solid $color-orange;

  &.in-routeItem {
    border-bottom: 0px;
  }

  &.in-popup {
    padding: 12px 18px;

    h3 {
      font-size: 14px;
    }

    .previous-requests {
      margin-top: 8px;
    }

    .button-wrapper {
      flex-direction: column;
  
      > * {
        max-width: unset;
      }

      h3 {
        font-size: 14px;
      }

      .request-button-wrapper {
        margin: unset;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  &.in-routeItem {
    padding: 15px 20px;
    flex-direction: row;
    
    h3 {
      font-size: 13px;
    }

    .button-wrapper {
      display: flex;
      width: 100%;

      .request-button-wrapper {
        margin: 0px;
      }
    }

    .previous-requests {
      margin-top: 5px;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;

    > * {
      max-width: 50%;
    }
  }

  .request-button-wrapper {
    margin-left: 25px;

    button {
      padding: 10px 15px;
      border-radius: 3px;
      color: $color-primary;
      background-color: #fff;
      border: none;
      font-family: $font-standard;
      font-weight: 500;
      font-size: $font-size-s;

      &:hover {
        color: $color-highlight;
        cursor: pointer;
      }

      &:disabled {
        color: $color-dark-gray;
        background-color: $color-border-light;
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    .request-button-wrapper {
      margin-left: 25px;
      margin-top: 10px;
    }
  }
}
