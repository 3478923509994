@import '../../../../style/variables.scss';

.saved-routes {
  padding: 10px 0;
  overflow: auto;
}

.saved-routes-button {
  margin: 0 auto 10px;
  text-align: center;
  display: block;
  box-shadow: $default-shadow;
}

.saved-routes-wrapper {
  margin-top: 10px;
  padding: 0;

  .list-title{
    font-size: $font-size-l;
    font-weight: 700;
  }
}