@import '../../../style/variables.scss';

.permissions-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.4) !important;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .permissions-dialog {
    display: flex;
    flex-direction: column;
    outline: none;
    width: 420px;
    max-width: 100vw;
    bottom: auto;
    right: auto;
    margin: 25px;
    border: none;
    color: $font-color;
    border-radius: $border-radius;
    z-index: 1001;
    max-height: 80%;
    overflow: auto;
    padding: 20px;
    line-height: 1.4;
    font-size: 14px;
    color: #454b4d;
    background: #fff;

    .title-header {
      margin: 0;
      text-align: left;
      font-weight: 600;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        cursor: pointer;
      }
    }
  }
}
