@import '../../../../style/variables.scss';

.time-options-wrapper {
  border-bottom: 1px solid $color-background-light;
  padding: 15px 0px 15px 35px;
  
  @media screen and (max-width: 400px) {

    padding: 15px 0px 15px 0px;
  }
  

  .option-label{
    // margin-top: 10px;
  }

  .time-inputs {

    .time-option {
      margin-top: 10px;
      background-color: $color-background-light;
      display: inline-block;
      color: $color-primary;
      font-weight: 700;
      margin-right: 10px;
      padding: 15px 10px;
      border-radius: $border-radius;
      cursor: pointer;

      i{
        display: inline-block;
        margin-right: 2px;
      }
      
      p{
        display: inline-block;
        margin: 0;
      }
    }

    .now-button{
      margin-top: 10px;
      padding: 15px;
      border-radius: $border-radius;
      border: none;
      background-color: #fff;
      font-weight: 700;
      background-color: $color-primary;
      color: #fff;
      cursor: pointer;
    }
  }
}

.datepicker-modal{
  background-color: rgba(75, 75, 75, 0.3);
  .datepicker-header{
    color: $color-primary !important;
    font-weight: 700 !important;
  }

  .datepicker-content{
    .datepicker-wheel{
      font-family: $font-standard;
      border-color: $color-primary !important;
      font-size: 12px;
      font-weight: 700 !important;
    }
  }
  
  .datepicker-navbar{
    .datepicker-navbar-btn{
      // color: $color-primary !important;
      font-weight: 700;
      font-size: 14px;
      background-color: $color-primary;
      color: #fff;
      margin-left: 10px;
      border-radius:$border-radius;
    }
  }
}