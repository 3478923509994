.validation-box {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 4px;

  .bubble-validation {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;

    margin-right: 12px;
  }

  .name {
    display: flex;
    flex-direction: column;
    .subtitle {
      font-size: 12px;
    }
  }

  &.valid {
    color: #10be0a;

    .bubble-validation {
      background-color: #10be0a;
      color: #fff;
    }
  }
  
  &.invalid {
    color: #DB0909;
    
    .bubble-validation {
      background-color: #DB0909;
      color: #fff;
    }
  }
}