@import '../../../style/variables.scss';

$menu-item-height: 20px;

.menu {
  display: flex;
  height: 100%;
  flex-direction: column;

  .menu-header {
    font-size: 14px;
    padding: $spacing-default;
    padding-top: calc(15px + env(safe-area-inset-top));
    background-color: $color-primary;
    color: #fff;
    border-bottom: 8px solid $color-light-red;

    h2 {
      margin-bottom: 2px;
    }

    .icon-close_privacy {
      float: right;
      font-size: $font-size-l;
      padding: $spacing-default;
      margin-right: -$spacing-default;
      margin-top: -$spacing-default;
      cursor: pointer;
    }
  }

  .menu-items {
    flex: 1 1 0%;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 100ms ease 0s;
    padding-top: $spacing-default;

    .menu-item {
      color: $font-color;
      text-decoration: none;
      padding: 20px $spacing-default;
      display: block;
      line-height: $menu-item-height;
      cursor: pointer;

      &.active {
        background: $color-lighter-gray;
        color: $color-primary;
      }
    }

    span {
      vertical-align: middle;
    }

    i {
      width: $menu-item-height;
      height: $menu-item-height;
      line-height: $menu-item-height;
      font-size: 18px;
      display: inline-block;
      margin-right: $spacing-default;
      vertical-align: middle;
    }
  }

  .menu-footer {
    font-size: 14px;
    padding: 15px 0px;
    color: $font-color;

    .logo_fryslan {
      height: 50px;
      background-image: url('../../../images/fryslanlogo.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .version {
      text-align: center;
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 12px;
    }
  }
}