.switch-button {
  background: #E3E3E3;
  border: none;
  padding: 0;
  border-radius: 100px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;

  .button,
  button {
    border: none;
    border-radius: none;
    outline: none;
    background: #E3E3E3;
    color: #909090;

    &:first-child {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
    
    &:last-child {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}