@import '../../style/variables.scss';

.item-info-details {
  padding: 20px;
  font-size: $font-size-s;
  font-weight: 400;
  color: $color-primary;
  background-color: $color-background-light;

  &.loading{
    padding: 25px;
  }

  b {
    color: $font-color;
  }
  .details-section {
    padding: 15px 0px; // border-top: 1px solid $color-background-light;
    padding-top: 0px;

    > div {
      margin-bottom: 8px;
    }
  }

  .opening-time-wrapper {
    display: flex;

    .opening-time {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $color-medium-gray;
      margin-bottom: 3px;
      padding-bottom: 3px;

      .rules {
        .rule {
          display: flex;
          justify-content: space-between;
          padding: 4px 6px;

          .time {
            width: 100%;
            max-width: 100px;
          }

          .note {
            width: 100%;
          }

          &:nth-of-type(odd) {
            background-color: rgba(50,50,50,0.09);
          }
        }
      }

      .dates {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        .date {
          font-weight: 600;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > div {
          max-width: 50%;
        }



        &.notes {
          flex-direction: column;
        }

        .note {
          display: flex;
          align-items: center;
          max-width: unset;
          font-weight: 700;
          width: 100%;
          font-size: 11px;
        }
      }
    }
  }
}

.watermeter img {
  margin-top: 25px;
  max-width: 100%;
}
