@import '../../style/variables.scss';

.register-form.login-container {
  display: table;
  vertical-align: middle;
  width: 100%;

  .field-error {
    color: $color-light-red;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  h3 {
    margin: 20px 0;
  }
  
  .login-form-inner {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.alt {
      flex-direction: column;

      .button.login {
        margin-top: 12px;
      }
    }
  }
  
  .login-form-wrapper {
    max-height: calc(100vh - 80px);
    overflow: auto;
    width: 100%;
  }
  

  .input-wrapper-padded {
    display: flex;
    align-items: center;

    .input-wrapper {
      width: 100%;
    }

    .button.peek-password {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #535353;
      height: 49px;
      width: 40px;
      border-radius: 0px;
      cursor: pointer;
    }

    &.no-button {
      margin-right: 40px;
    }
  }
  
  .react-select__value-container {
    padding: 0;
  }
  
  .react-select__control,
  .react-select__single-value,
  .textfield {
    padding-left: 0;
    margin-bottom: 15px;
  }

  .back-button {
    position: fixed;
    width: unset;
    top: 20px;
    left: 20px;
    color: $color-highlight;
    background-color: #fff;
    box-shadow: $default-shadow;
    padding: 10px 20px 10px 10px;
    text-decoration: none;
    z-index: 1;
    i {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 600px) {
  .register-form .login-form-wrapper {
      padding: 30px;
  }
}

@media screen and (max-width: 500px) {
  .register-form.login-container {
    .login-form-inner {
      display: flex;

      .login-form-wrapper {
        max-height: calc(100vh);
        height: 100%;
        overflow: auto;
        margin: 0;
        width: 100%;
        max-width: unset;
        padding-top: 60px;
      }
    }
  }
}