/*
 * COLORS
 */
/*
 * MIXIN
 */
.detailed-bridge-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-height: 175px;
  margin-top: 10px;
}

.detailed-bridge {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 175px;
  max-width: 250px;
  height: auto;
  font-size: 12px;
  color: #333;
}
.dark .detailed-bridge {
  color: white;
}
.detailed-bridge .bridge {
  height: 175px;
  display: flex;
  justify-content: flex-start;
}
.detailed-bridge .bridge.edge {
  height: 175px;
  width: 4px;
  background-color: #333;
}
.dark .detailed-bridge .bridge.edge {
  background-color: white;
}
.detailed-bridge .bridge.inner {
  width: 100%;
  min-width: 60px;
}
.detailed-bridge .bridge.water {
  width: 100%;
  height: 20px;
}
.detailed-bridge.waterlevel {
  width: 75px;
  position: relative;
}
.detailed-bridge.waterlevel .value {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  padding-right: 4px;
  margin-right: 4px;
}
.detailed-bridge.waterlevel .line {
  position: absolute;
  bottom: 25px;
  right: 0;
  height: 4px;
  width: 8px;
  border: 2px solid;
  border-color: rgba(51, 51, 51, 0.8);
}
.dark .detailed-bridge.waterlevel .line {
  border-color: rgba(255, 255, 255, 0.8);
}
.detailed-bridge .bridge.inner {
  position: relative;
}
.detailed-bridge .bridge.inner .parts {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: auto;
}
.detailed-bridge .bridge.inner .parts .bridgeWidth {
  width: 100%;
  border-top: 2px dashed rgba(51, 51, 51, 0.8);
  margin-top: 8px;
  text-align: right;
  padding-right: 4px;
}
.dark .detailed-bridge .bridge.inner .parts .bridgeWidth {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.detailed-bridge .bridge.inner .parts .closedHeight {
  border-left: 2px dashed rgba(51, 51, 51, 0.8);
  margin-left: 4px;
  padding-left: 4px;
  padding-top: 14px;
  line-height: 100%;
  vertical-align: middle;
  position: absolute;
  left: 0;
}
.dark .detailed-bridge .bridge.inner .parts .closedHeight {
  border-left-color: rgba(255, 255, 255, 0.8);
}
.detailed-bridge .bridge.inner .parts .openedHeight {
  border-left: 2px dashed rgba(51, 51, 51, 0.8);
  border-top: 4px solid rgba(51, 51, 51, 0.8);
  margin-left: 4px;
  padding-left: 4px;
  max-height: 204px;
}
.dark .detailed-bridge .bridge.inner .parts .openedHeight {
  border-left-color: rgba(255, 255, 255, 0.8);
  border-top-color: rgba(255, 255, 255, 0.8);
}
.detailed-bridge .bridge.inner .parts .left,
.detailed-bridge .bridge.inner .parts .right {
  position: absolute;
  height: 4px;
  background-color: #333;
  width: 50%;
}
.dark .detailed-bridge .bridge.inner .parts .left,
.dark .detailed-bridge .bridge.inner .parts .right {
  background-color: white;
}
.detailed-bridge .bridge.inner .parts .left {
  left: 0;
}
.detailed-bridge .bridge.inner .parts .right {
  right: 0;
}
.detailed-bridge .bridge.inner .water {
  z-index: 9;
  position: absolute;
  background-color: #29b8c5;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
}
.detailed-bridge.BC .bridge.inner .parts .left,
.detailed-bridge.DR .bridge.inner .parts .left {
  transform-origin: 0% 100%;
  transform: rotate(-25deg);
}
.detailed-bridge.BC .bridge.inner .parts .right,
.detailed-bridge.DR .bridge.inner .parts .right {
  transform-origin: 100% 100%;
  transform: rotate(25deg);
  width: 50%;
}
.detailed-bridge.end {
  width: auto;
}

@media screen and (max-width: 340px) {
  .detailed-bridge-wrapper {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .detailed-bridge-wrapper.large {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .detailed-bridge .bridge.inner .parts .bridgeWidth {
    visibility: hidden;
  }
}
