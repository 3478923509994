@import '../../style/variables.scss';

.ReactModal__Content.modal {
  overflow: auto !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  transform: unset !important;
  inset: unset !important;
  background-color: #fff;

  .activate-button {
    margin-right: 10px;
  }

  p {
    margin-top: 0;
  }

  .close-button {
    border: 1px solid $color-background-light;
  }
}

.page-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .content-wrapper-fullpage {
    max-width: 400px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11; // place just above the Mapbox
  }

  .content-wrapper-top {
    position: absolute;
    left: 15px;
    top: calc(15px + env(safe-area-inset-top));
    width: 500px;
    z-index: 11; // place just above the Mapbox
    max-height: calc(100% - 15px);
    display: flex;

    &.active-route-list {
      bottom: 0;
    }
  }

  .content-wrapper-bottom {
    position: absolute;
    bottom: 10px;
    left: 15px;
    right: 15px;
    z-index: 401;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.3s ease-in-out;
    max-width: 450px;
    box-sizing: border-box;

    &.active-route-list {
      z-index: 405;
      background-color: $color-background-light;
      box-shadow: $default-shadow;
    }
  }
}

@media screen and (max-width: 1000px) {
  .page-wrapper .content-wrapper-top {
    right: 15px;
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .page-wrapper .content-wrapper-bottom {
    left: inherit;
    right: inherit;
    bottom: 35px;
    z-index: 2;
  }
}
