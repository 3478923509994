@import '../../../style/variables.scss';

.input-wrapper {
  text-align: left;
  
  label {
    display: block;
    font-size: $font-size-s;
  }
  
  .textfield {
    font-size: $font-size-m;
  }
}