@import '../../style/variables.scss';

.navigation-page {
  .navigation-options-wrapper {
    position: absolute;
    top: #{$navigation-height};
    top: calc(#{$navigation-height} + env(safe-area-inset-top));
    bottom: 0;
    left: 0px;
    right: 0px;
    max-height: 100vh;
    max-height: calc(100vh - #{$navigation-height});
    max-height: calc(100vh - #{$navigation-height} - env(safe-area-inset-top));
    background-color: #fff;
    overflow: auto;
    box-shadow: $default-shadow;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    z-index: 10;
    max-width: 550px;
    margin-top:20px;

    .confirm-popup-wrapper {
      background-color: rgba(75, 75, 75, 0.3);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4;

      display: flex;
      align-items: center;
      justify-content: center;

      .confirm-popup {
        padding: 25px;
        background-color: #fff;
        max-width: 325px;

        .button-wrapper {
          width: 100%;
          display: flex;

          button {
            flex: 1;
            font-weight: 700;
            padding: 10px 15px;

            &.close {
              background: none;
              border: 1px solid $color-background-light;
              margin-left: 15px;
            }
          }
        }
      }
    }

    .tabs-wrapper {
      height: calc(100% - 50px);
      box-sizing: border-box;

      .tabs-header {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid $color-background-light;

        .tab {
          text-transform: uppercase;
          font-size: $font-size-m;
          list-style: none;
          padding: 20px 10px;
          color: $color-light-gray;
          &:hover {
            cursor: pointer;
          }
          &.active-tab {
            font-weight: 700;
            color: $color-primary;
            border-bottom: 2px solid $color-primary;
          }
        }
      }

      .tabs-content {
        -webkit-overflow-scrolling: touch;
        height: calc(100% - 60px);
        overflow: auto;
      }
    }

    .navigation-options-controls {
      height: 50px;
      display: flex;

      button {
        background-color: #fff;
        border: 1px solid $color-background-light;
        font-family: $font-standard;
        font-weight: 700;
        color: $color-primary;
        flex: 1;
        outline: none;

        &.stop {
          color: $color-error;
        }

        &.pause {
          color: $color-orange-dark;
        }
        &.resume {
          color: $color-green;
        }

        &.control-large {
          flex: 3;
        }

        &.control-small {
          flex: 1;
          color: $color-primary;
        }
      }
    }
  }

  .navigation-route-list {
    z-index: 402;
    position: absolute;
    top: 90px;
    width: 100%;
    height: calc(100% - 90px);
    min-height: calc(100vh - 90px);
    background-color: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: $default-shadow;
    border-top: 3px solid $color-primary;
    margin: 0;
  }

  .options-toggle {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1;

    .options-button {
      position: relative;

      .options-circle {
        background-color: #fff;
        font-size: $font-size-l;
        padding: 10px;
        border-radius: 50%;
        box-shadow: $default-shadow;
        color: $color-primary;
      }

      .notification-count {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 5px;
        font-weight: 700;
        border-radius: 50%;
        background-color: $color-orange;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 25px;
        height: 25px;
        border: 1px solid #fff;
        box-shadow: $default-shadow;
      }
    }
  }
}
