@import '../../../style/variables.scss';

.gps-notification-warning {
  position: absolute;
  bottom: 75px;
  left: 20px;
  z-index: 401;
  display: block;
  border-radius: 25px;
  padding: 5px;
  background-color: $color-error;
  font-size: $font-size-m;
  display: flex;
  flex-direction: column;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    padding: 5px;

    .notification-icon {
      background-color: $color-light-red;
      border-radius: 50%;
      color: #fff;
    
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      font-size: 12px;
      margin-right: 12px;
    }
  }

  .notification-tag {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 5px;

    display: flex;
    flex-direction: column;
    text-align: center;

    .retry {
      margin-top: 12px;
    }
  }

  &.info {
    background-color: $color-highlight;

    .icon-wrapper .notification-icon {
      background-color: $color-primary;
    }
  }

  &.collapsed {
    left: 0;
    right: unset;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .notification-tag {
      display: none;
    }

    .icon-wrapper {
      .notification-icon {
        margin: unset;
      }

      > span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .gps-notification-warning {
    right: 20px;
  }
}