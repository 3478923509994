@import '../../style/variables.scss';

.menu-select {
  display: block;
  padding: 12px 10px;
  color: $color-primary;
  font-size: $font-size-l;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}

.sidebar-content,
.sidebar-root {
  right: auto !important;
  bottom: auto !important;
  display: block;
  width: 44px;
  height: 49px;
  z-index: 10;
  overflow: visible !important;
}

.sidebar {
  z-index: 11 !important;
  right: inherit;
  width: 100%;
  max-width: 350px;
  height: 100vh;
  position: fixed !important;
  transition: all 0.5s ease 0s;
  background: rgb(255, 255, 255);
}

// Specific for the menu button inside the routeSelect component
.select-inline-inner-wrapper {
  .sidebar {
    top: calc(-15px - env(safe-area-inset-top)) !important;
    left: -15px !important;
    bottom: -15px !important;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS */
      top: 0px !important;
      left: 0px !important;
      bottom: 0px !important;
   }
  }

  .sidebar-overlay {
    display: none;
  }
}
