@import '../../../../style/variables.scss';

.via-routes-wrapper {
  &:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }
  .via-button {
    background: none;
    color: $color-primary;
    font-weight: 700;
    margin-top: 25px;
    font-size: $font-size-m;
    float: right;
    line-height: 20px;
    box-sizing: border-box;
    margin-right: 3px;

    &:hover{
      cursor: pointer;
    }

    .via-icon {
      font-size: $font-size-l;
      margin-left: 5px;
    }
  }
}
