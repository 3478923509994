@import '../../style/variables.scss';

.route-list-wrapper ul {
  margin: 0;
}

.route-plan-itemwrapper {
  position: relative;
  top: initial;
  bottom: initial;
  overflow: initial;
  -webkit-overflow-scrolling: touch;

  &::before {
    // LINE
    content: '';
    position: absolute;
    left: 68px;
    top: -14px;
    width: 3px;
    bottom: 0px;
    background: $color-background-light;
    z-index: 1;
  }
}
.route-list-item {
  width: 100%;
  padding: 0px 10px 0px 80px;
  margin: 0 auto;
  position: relative;
  margin: 15px 0;
  box-sizing: border-box;
  cursor: pointer;

  .bridge-can-open {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: $font-standard;
    padding: 10px 15px;
    font-size: 13px;
    background-color: $color-primary;
    border-bottom: 4px solid $color-orange;
    color: #fff;
    font-weight: 600;

    i {
      margin-right: 10px;
      min-width: 32px;
      text-align: center;
    }
  }

  .item-eta {
    // TIMES ON LEFT SIDE
    position: absolute;
    left: 3px;
    top: 22px;
    width: 55px;
    text-align: right;
    font-weight: 500;

    .eta-time {
      font-size: $font-size-s;
      font-weight: 700;
      color: $color-primary;
    }

    .time-to-reach {
      font-size: $font-size-xs;
      font-weight: 400;
    }
  }
  .list-item-icon {
    // ITEM DOT
    left: 64px;
    z-index: 1;
    background: $color-primary;
    border: 3px solid $color-primary;
    width: 12px;
    height: 12px;
    top: 23px;
    position: absolute;
    overflow: visible;
    border-radius: 50%;
    background-color: #fff;

    &.unpassable {
      background-color: $color-light-red;
      border-color: $color-error;
    }

    &.unknown {
      background-color: $color-lighter-gray;
      border-color: $color-light-gray;
    }
  }

  &.waiting-item-item {
    .info-wrapper {
      min-height: 40px;
    }
  }

  .info-wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px; // CONTENT TO THE RIGHT
    background-color: $color-background-light;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
    border-radius: $border-radius;

    &::after {
      content: '';
      clear: both;
      display: table;
    }
    .item-detail-icon {
      // OBJECT ICON
      font-size: 22px;
      margin-right: 10px;
      color: $color-primary;
      min-width: 32px;
      text-align: center;

      &.orange {
        font-size: 19px;
        color: $color-orange;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
    .vhf-sign {
      margin-left: auto;
      order: 2;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      padding: 2px;
      float: right;
      position: relative;
      border: 2px solid #c11111;
      border-radius: $border-radius;
    }
    .vhf-sign-content {
      font-size: 10px;
      text-align: center;
      margin-top: 2px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      line-height: 15px;
    }
    .passage-message {
      font-weight: 400;

      &.unpassable {
        color: $color-light-red;
      }

      &.opening-requested {
        color: $color-orange-dark;
      }

      &.opening-planned {
        color: $color-green;
      }
    }
    .item-title {
      font-size: $font-size-s;

      @media screen and (max-width: 400px) {
        max-width: 125px;
        word-wrap: break-word;
      }
    }
  }

  &.can-open {
    .info-wrapper {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &.expanded {
    .info-wrapper,
    .bridge-can-open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
