$color-primary: #244994; // PMS Reflex Blue
$color-highlight: #009bdf; // PMS 2995
$color-background-light: #f5f6fa;
$color-light-red: #c73326; // PMS RED 032
$color-error: #bd0040; // PMS 1935
$color-orange: #e8b620; // PMS 123
$color-orange-dark: #d98c32; // PMS 123
$color-green: #17a75f;
$color-dark-blue: #023361;
$color-purple: #642a86; // PMS 2592
$color-background: #bbdde6;

$color-white: #fff;
$color-lighter-gray: #efefef;
$color-light-gray: #b0b0b0;
$color-light-blue: #0155a5;
$color-medium-gray: #a5a5a5;
$color-dark-gray: #525252;
$font-light: #888;
$font-color: #444;
$color-border-light: #d1d1d1;

$font-standard: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;
$font-header: 'Montserrat', sans-serif;

$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 18px;
$font-size-xxl: 24px;
$font-weight-bold: 700;

$spacing-default: 15px;

$border-radius: 3px;

$default-shadow: 0 1px 6px -2px $color-dark-gray;
$map-element-shadow: 0px 4px 6px 0 hsla(0, 0%, 0%, 0.1);

$navigation-height: 90px;

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}
