@import '../../../style/variables.scss';

.route-selection-form {
  width: 100%;
  position: relative;
  padding-left: 35px;
  box-sizing: border-box;

  &.show-options {
    padding-bottom: $spacing-default;
    border-bottom: 1px solid $color-background-light;
  }

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 40px;
    width: 1px;
    bottom: 25px;
    background: $color-background-light;
  }

  .input-wrapper {
    .input-field-wrapper {
      position: relative;

      &::after {
        content: '';
        font-size: 8px;
        position: absolute;
        top: 27px;
        left: -34px;
        width: 10px;
        height: 10px;
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 50px;
        background: $color-primary;
      }

      .textfield {
        .remove-icon {
          float: right;
        }
      }

      &.via-route-field {
        &::after {
          background: #d4ebff;
        }
      }

      input {
        &:hover {
          cursor: pointer;
        }
      }

      .remove-via-route-button {
        position: absolute;
        right: 5px;
        top: 9px;
        background: none;
        color: $color-primary;
      }
    }
  }
}

.form-label {
  box-sizing: border-box;
  font-size: $font-size-s;
  color: $color-light-gray;
  letter-spacing: 0.5px;
}

.button-wrapper {
  margin-top: 25px;

  &:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }

  button {
    font-weight: 700;
    float: right;
    font-size: $font-size-m;
  }

  .options-toggle {
    margin-right: 10px;
    background: none;
    color: $color-primary;
    border: 1px solid $color-lighter-gray;
    box-sizing: border-box;
  }
}