@import '../../style/variables.scss';

.settings-form {
  margin-bottom: 200px;

  .version {
    font-size: 12px;
    opacity: 0.6;
  }

  .form-row {
    label {
      min-width: 200px;
    }

    input.input {
      margin-right: unset;
    }
  }
}

.delete-accout-button, .save-button{
  margin-top: 10px;
  margin-bottom: 10px;
} 

input.save-button{
  margin-right: 10px;
  border-radius: $border-radius !important;
}


.input{
  margin-right:5px;

  &.disabled{
    background-color: rgb(221, 221, 221);
  }
}

.error-message{
  color: $color-light-red;
  font-weight: 700;
  font-size: $font-size-s;
}

.password-wrapper {
  display: flex;
  width: 100%;

  input {
    width: 100%;
  }

  .button-peek {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    background-color: #dadada;
    color: #000;
    cursor: pointer;
    border-radius: $border-radius;
    margin-left: 8px;
  }
}

.password-validation {
  display: flex;
  width: 100%;
  flex-direction: row;
  // border-top: 1px solid #dadada;
  padding-top: 16px;

  .title {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .requirements, .recommendations {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dadada;
    max-width: 50%;
    
    .info-box {
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 16px
    }

    .successful {
      margin-top: 16px;
      margin-bottom: 0px;
    }
  }

  .requirements {
    padding-right: 24px;
  }

  .recommendations {
    padding-left: 24px;
  }

  .success {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 770px) {
  .password-validation {
    flex-direction: column;

    .requirements, .recommendations {
      max-width: unset;
      padding-left: unset;
      padding-right: unset;
    }

    .requirements {
      border-bottom: 0px;
      margin-bottom: 8px;
    }

    .validation-box {
      .bubble-validation {
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        font-size: 12px;
      }
    }
  }
  
}