@import '../../style/variables.scss';

.feedback-page-wrapper {
  .page-block {
    position: relative;
    p {
      font-size: $font-size-m;
    }

    &.danger {
      background-color: $color-light-red;
      color: #fff;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
  }

  .description-block {
    textarea {
      width: 100%;
      resize: none;
      min-height: 200px;
      font-family: $font-standard;
      margin-top: 15px;
      padding: 10px;
      font-size: $font-size-m;
      border: 1px solid $color-border-light;
      border-radius: 0;
      -webkit-appearance: none;
    }
  }

  .feedback-map-wrapper {
    display: block;
    height: 300px;
    width: 100%;

    .mapboxgl-map {
      width: 100%;
      height: 100%;
    }
  }

  .file-upload-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: $color-background-light;

    .upload-message {
      font-size: $font-size-m;
    }

    li {
      list-style-type: none;
    }
  }
}

.feedback-flag {
  width: 16px;
  height: 16px;
  background-color: #4185f4;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 5px rgba(65, 133, 244, 0.3);
}

.map-wrapper {
  height: calc(100% - 50px);
  width: 100%;
  position: absolute;
  left: 0;

  .page-block.title {
    position: absolute;
    bottom: calc(30px + env(safe-area-inset-bottom));
    left: 10px;
    right: 10px;
    z-index: 10001;
    border-radius: 3px;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $default-shadow;
  }

  .mapboxgl-map {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    user-select: none;

    .mapbox-improve-map {
      display: none;
    }
    canvas {
      outline: none;
    }
    .mapboxgl-popup {
      z-index: 99 !important; // Override Mapbox inline-style
    }
  }
}
